
import {
  Component,
  Vue
} from 'vue-property-decorator'
import {
  ToastModule
} from '@/store/modules/ToastModule'
import CompanyModalService from './../services/company-modal-service'
// import { CompanyModule } from '../store/CompanyModule'
import { MasterModule } from '@/views/invoice/store/MasterModule'
import Currencies from '@/data/currency.json'
import Countries from '@/data/countries.json'
import {
  AuthModule
} from '@/store/modules/AuthModule'
import {
  Validations
} from 'vuelidate-property-decorators'
import {
  Vuelidate
} from 'vuelidate'
import {
  email,
  alpha,
  alphaNum,
  numeric,
  required,
  minLength,
  maxLength
} from 'vuelidate/lib/validators'

  @Component({
    name: 'ClientRegister'
  })
export default class ClientRegister extends Vue {
    protected tabIndex = 1
    protected isEditForm = false
    protected countries = Countries
    protected currencies = Currencies
    protected addressTypes = ['Secondary', 'Temporary', 'Other']
    protected accountList: any = []
    protected account: any = null
    protected addressType: any = null
    protected banks: any = []
    protected bank: any = {}
    protected branches: any = []
    protected branch: any = {}

    protected company: any = {
      id: null,
      name: null,
      display_name: null,
      code: null,
      email: null,
      vat_no: null,
      registration_no: null,
      mobile: null,
      phone: null,
      other: null,
      fax: null,
      web_site: null
    }

    protected primaryAddress: any = {
      id: null,
      type: null,
      address: null,
      postal_code: null,
      country: null
    }

    protected address: any = {
      id: null,
      type: null,
      address: null,
      postal_code: null,
      country: null
    }

    protected bankAccount: any = {
      id: null,
      account_name: null,
      account_number: null,
      bank_id: null,
      branch_id: null,
      currency: null,
      status: null
    }

    $v: Vuelidate

    @Validations()
    validations = {
      company: {
        name: {
          required,
          minLength: minLength(5)
        },
        display_name: {
          required,
          minLength: minLength(5)
        },
        email: {
          email
        },
        code: {
          alpha,
          required,
          minLength: minLength(6),
          maxLength: maxLength(8)
        },
        vat_no: {
          numeric
        },
        registration_no: {
          alphaNum
        },
        phone: {
          minLength: minLength(10),
          numeric
        },
        mobile: {
          minLength: minLength(10),
          numeric
        },
        other: {
          minLength: minLength(10),
          numeric
        },
        fax: {
          minLength: minLength(10),
          numeric
        },
        web_site: {
          minLength: minLength(8),
          maxLength: maxLength(100)
        }
      }
    }

    created () {
      this.boot()
    }

    private boot () {
      //
    }

    public open () {
      (this.$refs.CompanyModal as any).show()
    }

    public close () {
      this.isEditForm = false
      this.cancel();
      (this.$refs.CompanyModal as any).hide()
    }

    public cancel () {
      this.reset(this.company)
      this.reset(this.primaryAddress)
      this.reset(this.address)
      this.reset(this.bankAccount)
      this.reset(this.bank)
      this.reset(this.branch)
      this.banks = []
      this.branches = []
      this.accountList = []
      this.account = null
      this.addressType = null
      // MasterModule.resetCompanyChange()
      this.$nextTick(() => {
        this.$v.company.$reset()
      })
    }

    public populateCompany (id: any) {
      CompanyModalService.getCompany(id).then((response) => {
        this.isEditForm = true
        this.company = response.data
        if (response.data.primary_address !== null) {
          this.primaryAddress = {
            id: response.data.primary_address.id,
            address: response.data.primary_address.address,
            postal_code: response.data.primary_address.postal_code,
            country: response.data.primary_address.country
          }
        }
        this.setAccountList(response.data.bank_accounts)
      })
      this.populateBanks()
    }

    protected generateCompanyCode () {
      this.company.code = this.company.name.substring(0, 8).replace(/\s/g, '').toUpperCase()
      if (this.company.code.length > 6) this.checkCompanyCode(this.company.code)
    }

    protected checkCompanyCode (code: any) {
      CompanyModalService.checkCompany(code).then((response) => {
        if (response.data.status) alert('Company Already Exist')
      })
    }

    protected setAddress (type) {
      this.reset(this.address)
      CompanyModalService.getAddress(this.company.id, type).then((response) => {
        if (response.data !== 'empty') {
          this.address = response.data
        }
      })
    }

    protected setAccountList (accounts) {
      this.accountList = []
      accounts.forEach((account) => {
        this.accountList.push({
          value: account.id,
          text: account.account_name
        })
      })
    }

    protected setBankAccount (accountId) {
      this.reset(this.bank)
      this.reset(this.branch)
      this.reset(this.bankAccount)
      if (accountId !== null) {
        CompanyModalService.getAccountDetails(accountId).then((response) => {
          this.populateBankBranches(response.data.bank_id)
          this.bankAccount = response.data
          this.bank = {
            id: response.data.bank_id,
            name: response.data.bank_name,
            swift_code: response.data.swift_code
          }
          this.branch = {
            id: response.data.branch_id,
            name: response.data.branch_name
          }
        })
      }
    }

    protected populateBanks () {
      this.banks = []
      this.branches = []
      CompanyModalService.getBanks().then((response) => {
        response.data.forEach((bank) => {
          this.banks.push({
            value: {
              id: bank.id,
              name: bank.name,
              swift_code: bank.swift_code
            },
            text: bank.name
          })
        })
      })
    }

    protected populateBankBranches (bankId) {
      this.branches = []
      CompanyModalService.getBankBranches(bankId).then((response) => {
        response.data.forEach((branch) => {
          this.branches.push({
            value: {
              id: branch.id,
              name: branch.name
            },
            text: branch.name
          })
        })
      })
    }

    protected save () {
      this.company.status = 'active'
      this.primaryAddress.type = 'Primary'
      this.address.type = this.addressType
      this.bankAccount.bank_id = this.bank.id
      this.bankAccount.branch_id = this.branch.id
      this.bankAccount.status = 'active'
      this.primaryAddress.source_type = this.address.source_type = this.bankAccount.source_type = 'Company'
      this.primaryAddress.source_id = this.address.source_id = this.bankAccount.source_id = this.company.id
      this.company.created_user = this.primaryAddress.created_user = this.address.created_user = this.bankAccount.created_user = AuthModule.user.fullname
      this.company.modified_user = this.primaryAddress.modified_user = this.address.modified_user = this.bankAccount.modified_user = AuthModule.user.fullname

      const company: any = {}
      company.basic = this.company
      company.primary_address = this.primaryAddress.address !== null || this.primaryAddress.postal_code !== null || this.primaryAddress.country !== null ? this.primaryAddress : null
      company.address = this.address.type !== null && (this.address.address !== null || this.address.postal_code !== null || this.address.country !== null) ? this.address : null
      company.bank_account = this.bankAccount.account_name !== null || this.bankAccount.account_number !== null ? this.bankAccount : null

      delete company.basic.primary_address
      delete company.basic.bank_accounts
      if (company.bank_account !== null) {
        delete company.bank_account.bank_name
        delete company.bank_account.branch_name
        delete company.bank_account.swift_code
      }

      if (this.isEditForm) this.update(company)
      else this.create(company)
    }

    protected create (company: any) {
      CompanyModalService.create(company).then((response) => {
        ToastModule.message(response.data.message)
        MasterModule.setCompanyChange()
        this.close()
        // (this.$parent as any).populateCompanies()
      }).catch(error => {
        console.log(error)
      })
    }

    protected update (company: any) {
      CompanyModalService.update(company).then((response) => {
        ToastModule.message(response.data.message)
        MasterModule.setCompanyChange()
        this.close()
        // (this.$parent as any).populateCompanies()
      }).catch(error => {
        console.log(error)
      })
    }

    protected reset (obj: any) {
      for (const key in obj) {
        obj[key] = null
      }
    }
}

