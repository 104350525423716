import APIService from '@/services/api-service'
const resource = '/company'

class CompanyService extends APIService {
  public constructor () {
    super()
    this.mountAuthorizationToken()
  }

  public getCompanies () {
    return this.connector.get(`${resource}/all`)
  }

  public save (data: any) {
    return this.connector.post(`${resource}/save`, data)
  }

  public checkCompany (code: any) {
    return this.connector.get(`${resource}/check/${code}`)
  }

  public getCompany (id: any) {
    return this.connector.get(`${resource}/model/${id}`)
  }

  public saveAddress (data: any) {
    return this.connector.post(`${resource}/save/address`, data)
  }

  public saveBank (data: any) {
    return this.connector.post(`${resource}/save/bank`, data)
  }
}

export default new CompanyService()
