import APIService from '@/services/api-service'
const resource = '/company'

class CompanyModalService extends APIService {
  public constructor () {
    super()
    this.mountAuthorizationToken()
  }

  public getCompany (id: any) {
    return this.connector.get(`${resource}/${id}`)
  }

  public checkCompany (code: any) {
    return this.connector.get(`${resource}/check/${code}`)
  }

  public getAddress (companyId: any, type: any) {
    return this.connector.get(`${resource}/${companyId}/address/${type}`)
  }

  public getBanks () {
    return this.connector.get(`${resource}/banks`)
  }

  public getBankBranches (bankId: any) {
    return this.connector.get(`${resource}/bank-branches/${bankId}`)
  }

  public getAccountDetails (accountId: any) {
    return this.connector.get(`${resource}/bank-account/${accountId}`)
  }

  public create (company: any) {
    return this.connector.post(`${resource}/create`, { company: company })
  }

  public update (company: any) {
    return this.connector.put(`${resource}/update`, { company: company })
  }
}

export default new CompanyModalService()
