
import {
  Component,
  Vue
} from 'vue-property-decorator'
import CompanyService from './services/company-service'
import CompanyModal from './components/CompanyModal.vue'
import {
  BreadcrumbModule
} from '@/store/modules/BreadcrumbModule'

  @Component({
    name: 'Clients',
    components: {
      CompanyModal
    }
  })
export default class Clients extends Vue {
    protected isBusy = false
    protected companies = []
    protected perPage = 10
    protected pageOptions: any = [10, 25, 50, 100]
    protected currentPage = 1
    protected searchTable = null
    private fields = [{
      key: 'index',
      label: '#',
      thStyle: {
        width: '3%'
      },
      class: 'text-center'
    },
    {
      key: 'name',
      thStyle: {
        width: '20%'
      }
    },
    {
      key: 'code',
      thStyle: {
        width: '10%'
      }
    },
    {
      key: 'email',
      thStyle: {
        width: '15%'
      }
    },
    {
      key: 'number',
      thStyle: {
        width: '10%'
      }
    },
    {
      key: 'registration_no',
      thStyle: {
        width: '10%'
      }
    },
    {
      key: 'created_date',
      thStyle: {
        width: '10%'
      }
    },
    {
      key: 'action',
      label: '',
      thStyle: {
        width: '10%'
      },
      class: 'text-center'
    }
    ]

    get rows () {
      return this.companies.length
    }

    created () {
      this.boot()
    }

    protected boot () {
      BreadcrumbModule.setBreadcrumb(this.$route)
      this.populateCompanies()
    }

    public openModal () {
      (this.$refs.CompanyInfoModal as any).open()
    }

    public closeModal () {
      (this.$refs.CompanyInfoModal as any).close()
    }

    public async populateCompanies () {
      this.isBusy = true
      const response = await CompanyService.getCompanies()
      this.companies = response.data
      this.isBusy = false
    }

    protected edit (id: any) {
      (this.$refs.CompanyInfoModal as any).populateCompany(id);
      (this.$refs.CompanyInfoModal as any).open()
    }
}

